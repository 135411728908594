export default {
  groupControl: 'Synchronize System',
  groupControlLabel: 'Synchronize System: ',
  groupControlDesc: 'Browsers Sync All in One',
  proxyIp: 'Proxy IP',
  proxyIpDesc: 'Integrate Global Proxy IP resources',
  chuhai2345: 'Chuhai2345',
  chuhai2345Desc1: '',
  chuhai2345Desc2: 'Integrate the Best Cross-border Resources',
  scriptMarket: 'Script Market',
  scriptMarketDesc: 'Script Customization Platform',
  zhiwenshouji: 'Anti-Phone',
  zhiwenshoujiDesc: 'Anti-association Environment & Unmanned Live Broadcast',
  userLabel: 'User: ',
  roleLabel: 'Role: ',
  balanceLabel: 'Balance: ',
  rechargeLabel: 'Recharge',
  getCoupon: 'Coupon(20% Off) For Old Users',
  package: 'Package:',
  browsers: 'Profiles:',
  unit: '',
  users: 'Users:',
  additionUsers: 'Additional Users:',
  expired: 'Expired: ',
  browsersCount: 'Total: ',
  used: 'Used: ',
  assigned: 'Assigned: ',
  activate: 'Subscribe',
  totalUsers: 'Total: ',
  controlBrowsers: 'Total: ',
  groupControlDocs: 'Guide (Graphic)',
  groupControlVideoDocs: 'Guide (Video)',
  qa: 'Q&A',
  open: 'Open',
  notice: 'Notice',
  docs: 'Docs',
  help: 'Help',
  issues: 'Issues',
  apiDocs: 'API Documents (Support Selenium And Puppeteer Script)',
  createdTime: 'Created Time: ',
  close: 'Close',
  groupControlOpened: 'Synchronize System has opened.',
  groupControlOpenFail:
    "Synchronize System failed to open. Please ensure a smooth network connection and try again. If your system hasn't installed the .NET 4.6.1 environment, click the button below to download and install it, then try again!",
  upgrade: 'Please upgrade the client and retry.',
  couponText: `
    <p>Geted, please view at "Cost -> Coupon"</p>
    <p class="q-mt-sm">Could be used for Subscribe Package/Change Plan/Update Users!</p>
  `,
  downloadApp: 'The Synchronize System only supports Windows system, Please download the Windows version client if you need!',
  noExpired: 'Permanently Available',
  downloadDonet: '[Download .NET4.6.1]',
  lastVersionQunkongTitle: 'Synchronize System 4.0.5 (Old Version)',
  currentVersionQunkongTitle: 'Synchronize System 5.X (Latest Version)',
  currentVersionQunkongMsg:
    'Added support for mouse event delay synchronization, random number input, and adjustable text simulation input speed on top of version 4.0.5. Continuously updating and optimizing various auxiliary functions. If you experience synchronization instability due to computer configuration or other issues, please provide feedback to customer support, and we will assist with optimization.',
  lastVersionQunkongMsg:
    'In addition to the features in version 3.1.4, we have added support for mouse and keyboard synchronization extensions/pop-up menus, along with some auxiliary functions! There will be no further updates or optimizations; for the latest features, please use the latest version!',
  oldVersionQunkongTitle: 'Synchronize System 3.1.4 (Old Version)',
  oldVersionQunkongMsg1:
    'Not supported: mouse and keyboard sync extensions/pop-up menus, but some long-time users find it more stable; no more updates or optimizations, for the latest features, please use the latest version!',
  oldVersionQunkongMsg2: `Note: For older versions 3.1.4 and 4.0.5, after clicking, once the software package is downloaded and extracted, run 'SynchronousOperation.exe' or 'Run BitBrowser Synchronize System.bat' to get started!`,
  weChatExchangeGroup: 'Join WeChat User Group',
  qunkongMsg1:
    'If the Synchronize System is running abnormally and unable to upgrade automatically, please delete the Synchronize System first and then run it again on this page!',
  qunkongMsg2: 'Click to download "Delete BitBrowser Synchronize System.bat"',
  qunkongMsg3: ', running this file can quickly remove the Synchronize System!',
  loginTipsForFreeAccount:
    'To ensure a consistent experience for free users, only the first free account logged in on a device will function normally, while others on the same device or in virtual machines will be restricted from opening profiles.<p class="text-red q-mt-sm">If you use multiple free accounts, please comply with these guidelines! Paid users are not affected by these restrictions.</p>',
  apiErrorLogsTitle: 'API request error log',
  apiErrorLogsTips:
    'Your API interface requests are causing too frequent errors, triggering the security mechanism and resulting in automatic logout. Please debug the API interface and run your script again after it is functioning normally. The following is the error message:',
  groupControlSubLabel: '(Only supports Chrome, not Firefox)',
  loginTipsAction: 'I acknowledge and agree',
  forcePasswordChangeTip:
    'To ensure the security of your BitBrowser account, we strongly recommend that you change your login password regularly. This time, please be sure to change your password and log in to BitBrowser again. We apologize for any inconvenience caused!',
  goChange: 'Change Now'
}
