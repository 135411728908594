export default {
  baseinfo: 'Базовая информация',
  name: 'Фамилия и имя',
  username: 'Имя пользователя',
  email: 'Электронная почта',
  bindEmail: 'Привязать электронную почту',
  qqPlaceholder: 'Введите WeChat/QQ',
  qq: 'WeChat/QQ',
  save: 'Сохранить информацию',
  changeTel: 'Изменить номер телефона',
  changeEmail: 'Изменить почтовый ящик',
  currentTel: 'Текущий номер телефона',
  currentEmail: 'Текущая почта',
  newEmail: ' Новая почта',
  codePlaceholder: 'Ввести номер для поиска',
  newTel: 'Новый номер телефона',
  telPlaceholder: 'Введите номер телефона',
  verifyCodePlaceholder: 'Введите проверочный код.',
  emailverifyCode: 'Введите проверочный код из электронного письма',
  verifyEmailPlaceholder: 'Введите отправленный на эл. почту проверочный код ',
  verifyCode: 'Код подтверждения',
  checkType: 'Тип проверки',
  emailRegister: 'Через эл. почту',
  phoneRegister: 'Через  номер телефона',
  passwordPlaceholder: 'Введите пароль',
  password: 'Пароль',
  confirmChange: 'Подтвердить изменение',
  passwordSet: 'Настройка пароля',
  oldPassPlaceholder: 'Введите старый пароль',
  oldPassword: 'Старый пароль',
  newPassPlaceholder: 'Введите новый пароль',
  newPass: 'Новый пароль',
  newPassAgain: 'Повторите новый пароль',
  confirmPassword: 'Подтвердите пароль',
  confirmPassword1: 'Подтвердите пароль',
  savepass: 'Изменить пароль',
  passRule1: 'Введите новый пароль еще раз',
  passRule2: 'Пароли не совпадают',
  nationRule: 'Выберите страну',
  resentTips: ' Переотправить через {seconds} секунд.',
  send: 'Получить код',
  qqChangeConfirm: 'Вы уверены, что хотите изменить электронную почту?',
  passChangeConfirm: 'Вы уверены, что хотите изменить пароль?<br/>После изменения пароля используйте новый пароль для входа!',
  passChangeSuccess: 'Пароль успешно изменен',
  telChangeConfirm: 'Вы уверены, что хотите привязать этот номер телефона?',
  telChangeSuccess: 'Номер телефона успешно изменен',
  telChangeError: 'Этот номер телефона уже привязан к другому аккаунту. Пожалуйста, выберите другой номер телефона!',
  emailChangeError: 'Email уже связан с другим аккаунтом. Пожалуйста, измените email',
  sendSuccess: 'Отправлено успешно',
  forgetUsername: 'Восстановление имени пользователя',
  findAccount: 'Восстановление имени пользователя',
  yourUsername: 'Ваше имя пользователя',
  getBack: 'Восстановить',
  welcome: 'Привет, добро пожаловать в систему',
  backLogin: 'Вернуться ко входу',
  verifyRule1: 'Введите динамический код',
  verifyRule2: 'Код состоит из 6 цифр',
  usernameCopied: '{name} скопировано в буфер обмена',
  usernamePlaceholder: 'Введите имя пользователя',
  usernamePlaceholder1: 'Введите имя/телефон/эл. почту',
  loginPassPlaceholder: 'Введите пароль',
  loginPassPlaceholder1: 'Введите пароль для входа',
  loginPass: 'Пароль',
  rememberPass: 'Запомнить пароль',
  forgetPass: 'Забыли пароль?',
  login: 'Войти',
  toRegister: 'Зарегистрируйтесь',
  signingIn: 'Выполняется вход...',
  welcomeRegister: 'Привет, добро пожаловать на регистрацию в системе',
  registerUsername: 'Введите имя пользователя a~z 0~9',
  tel: 'Номер телефона',
  registerQQPlaceholder: 'Введите WeChat/QQ (опционально)',
  registerEmail: 'Введите электронную почту (опционально)',
  inviteCodePlaceholder: 'Введите код приглашения (опционально) ',
  inviteCode: 'Код приглашения',
  register: 'Зарегистрируйтесь',
  readAndKnow: 'Согласен',
  userPolicy: 'Условия предоставления услуг',
  haveAccount: 'Уже есть аккаунт?',
  toLogin: 'Войти',
  userPolicyDialog: 'Условия предоставления услуг',
  registerUsernameRule: 'Минимальная длина имени пользователя - 6 символов',
  inviteCodeRule: 'Максимальная длина кода приглашения - 10 символов',
  policyValid: 'Пожалуйста, прочтите и примите «Условия предоставления услуг»',
  registerSuccess: 'Регистрация успешна. Хотите скачать клиентское ПО сейчас?',
  downloadNow: 'Скачать сейчас',
  downloadLater: 'Скачать позже',
  registerAndLogin: 'Регистрация прошла успешно. Войдите в систему',
  resetPass: 'Сбросить пароль',
  resetConfirm: 'Подтвердить изменение',
  resetSuccess: 'Пароль успешно сброшен',
  '2fAuth': 'Двухфакторная аутентификация',
  otherSetting: 'Проверка пароля',
  opendeleteWindowvVer: 'Включить проверку пароля перед окончательным удалением окна',
  enable2fa: 'Включить двухфакторную аутентификацию',
  enableConfirm:
    'Вы уверены, что хотите включить двухфакторную аутентификацию?<p class="q-mt-sm">После включения вам потребуется двухфакторная аутентификация при входе в приложение.</p><p class="q-mt-sm text-red">Внимание: Пожалуйста, убедитесь, что вы можете получать SMS-сообщения на ваш текущий мобильный номер, чтобы избежать проблем с двухфакторной аутентификацией!</p>',
  disable2faConfirm:
    'Вы уверены, что хотите отключить двухфакторную аутентификацию?<p class="q-mt-sm">После отключения вам потребуется только имя пользователя и пароль для входа.</p>',
  enablePasswordVerification:
    'Вы уверены, что хотите включить проверку пароля перед окончательным удалением окна?<p class="q-mt-sm text-red">После включения, при удалении окна браузера потребуется ввод пароля для подтверждения.</p>',
  disablePasswordVerification:
    'Вы уверены, что хотите отключить проверку пароля перед окончательным удалением окна?<p class="q-mt-sm text-red">После отключения, при удалении окна браузера не потребуется ввод пароля для подтверждения.</p>',
  authenticator: 'Аутентификатор',
  bind: 'Привязать',
  unbind: 'Отвязать',
  bound: 'Привязано',
  secretKey: 'Секретный ключ аутентификатора',
  googleAuthCode: 'Код аутентификатора',
  googleCodePlaceholder: 'Введите код, сгенерированный аутентификатором',
  bindTitle: 'Привязать аутентификатор',
  scanQrCode: 'Сканировать QR-код',
  authValidation: 'Привязка аутентификации',
  scanTips: 'Подсказка: Используйте аутентификатор для сканирования QR-кода ниже или скопируйте секретный ключ.',
  unbindTitle: 'Отвязать аутентификатор',
  recommended: 'Рекомендуемый аутентификатор',
  googleAuthenticator: 'Аутентификатор Google',
  microsoftAuthenticator: 'Аутентификатор Microsoft',
  tencentAuthenticator: 'Аутентификатор Tencent',
  sms: 'SMS-подтверждение',
  validationMethod: 'Метод подтверждения',
  smsCode: 'SMS-код',
  emailCode: 'Email-код',
  requiredEmail: 'Введите адрес электронной почты',
  emailPlaceholder: 'Введите адрес электронной почты',
  emailVerification: 'Подтверждение почты',
  phoneVerification: 'Подтверждение телефона',
  '2fAuthTips': 'Подключите пакет услуг, чтобы включить эту функцию',
  vistualTelTips:
    'Учетная запись, зарегистрированная с использованием виртуального номера телефона, подвержена риску взлома. При входе в систему будет активирована проверка безопасности. Убедитесь, что этот номер может принимать SMS-коды в любое время. Настоятельно рекомендуется использовать реальный номер телефона при регистрации аккаунта.',
  secureAuth: 'Проверка безопасности личности',
  selectSymbol: 'Выберите валюту расчетов',
  SettlementCurrency: 'Расчетная валюта',
  SettlementCurrencyMsg: 'Валюта, используемая для расчета, не может быть изменена после регистрации. Пожалуйста, выбирайте внимательно!',
  securitySetting: 'Настройки безопасности',
  unbound: 'Не привязано',
  emailAuth: 'Аутентификация почтового ящика',
  bindPhone: 'Привязать номер телефона',
  telAuthenticationTitle: 'Подтвердить номер телефона',
  accreditation: 'Подтверждение',
  passwordLogin: 'Вход по паролю',
  verificationLogin: 'Вход по коду подтверждения',
  userNamephone: 'Имя пользователя/Телефон/Эл. почта',
  phoneLoginTip: 'Не вводите код региона при вводе номера телефона.',
  authenticated: 'Аутентифицировано',
  phoneEmailLoginTip:
    'Примечание: Вы можете войти в {arg} напрямую, используя успешно аутентифицированный номер телефона/электронной адрес почты.',
  toAuthen: 'Аутентификация',
  notCertified: 'Не аутентифицировано',
  isFreeAccountBindTip: 'Примечание: После подписки на пакет поддерживается привязка.',
  verificationType: 'Тип верификации',
  personalVer: 'Личная верификация',
  enterpriseVer: 'Корпоративная верификация',
  AuthenticationName: 'Имя аутентификации',
  detailAuth: 'Данные аутентификации',
  upgradeEnterprise: 'Обновление корпоративной аутентификации',
  openTransBrowserPassword: 'Включить проверку пароля для передачи/обмена данными окна',
  enablePasswordVerification1:
    'Подтвердите включение проверки пароля для передачи/обмена данными окна.<p class="q-mt-sm text-red">После включения проверки пароля потребуется ввод пароля при передаче/обмене данными окна браузера.</p>',
  disablePasswordVerification1:
    'Подтвердите отключение проверки пароля для передачи/обмена данными окна.<p class="q-mt-sm text-red">После отключения проверки пароля при передаче/обмене данными окна браузера ввод пароля не потребуется.</p>',
  changePassword: 'Изменение текущего пароля',
  changeVerificationCode: 'Изменение кода подтверждениня через телефон/по эл.почте',
  emailResitertip:
    'Не используйте временные адреса электронной почты для регистрации аккаунтов, так как эти аккаунты могут оказаться недоступными.',
  privacyPolicy: 'Политика конфиденциальности'
}
