export default {
  balance: 'Баланс',
  renew: 'Продлить',
  freePackage: 'Бесплатный пакет',
  statistics: 'Статистика посещений',
  recharge: 'пополнить',
  coupon: 'Купон',
  model: 'Модель устройства ',
  bill: 'Счет',
  month: 'месяц',
  days: 'дни',
  expiredDays: '',
  expire: 'скоро',
  freePackage: 'Бесплатный пакет',
  noExpired: 'Бессрочно',
  billDetail: 'Подробности счета',
  pkgLabel: 'Браузер: ',
  users: 'Пользователи',
  additionUsers: 'Дополнительные:',
  activate: 'Активировать',
  change: 'комбо',
  changeUsers: 'Изменить',
  preferential: '※ Скидочное мероприятие, действительно только с 2023.9.5 по 2023.10.10.',
  todayOpen: 'открытий сегодня',

  openCountComment: 'Макс. открытий в день = общ. кол-во окон x {count}. Превышение - запрет на день, в следующий день снова доступно.',
  ordersNotPay: 'Неоплаченные заказы (USDT)',
  moneyUnit: '¥',
  unit: '',
  paymentOrder: 'Продолжить оплату',
  paymentOrderDialog: 'Продолжить оплату заказа',
  paid: 'Оплачено',
  wechatPay: 'Оплата через WeChat',
  balancePay: 'Оплата с баланса',
  alipay: 'Оплата через Alipay',
  rechargePay: 'Пополнение',
  withdrawPay: 'Пополнение через вывод',
  time: 'Время',
  payMoney: 'Сумма платежа',
  payType: 'Способ оплаты',
  accountBalance: 'Баланс аккаунта',
  payRemark: 'Подробности платежа',
  additionErrMsg: 'Бесплатный пакет не поддерживает изменение количества дополнительных сотрудников. Сначала активируйте платный пакет',
  paySuccess: 'Платеж успешно выполнен',
  changeInfo: '<strong>Классический пакет</strong> (Исходный пакет: {browserCount} окна браузера, {users} пользователей)',
  pkgChangeLabel: 'Классический пакет',
  pkgChangeLabel2: 'Исходный пакет: окна браузера',
  pkgChangeLabel3: 'Количество сотрудников',
  pkgBrowsers: 'Окна браузера: {count}',
  pkgUsers: 'Сотрудники: {count}',
  pkgUnit: '/месяц',
  activateMonths: 'Период активации',
  useBalance: 'Баланс',
  balanceAssets: 'Доступный баланс: {balance}юаней',
  paypalTips: 'Поддерживается только оплата через международный аккаунт PayPal',
  totalBrowsers: 'Общее количество окон браузера: {count} шт.',
  pkgExpired: 'Дата истечения срока пакета:',
  pkgOpenCountTips: 'Примечание:{browsers} x {maxCount} окон/день. Превышение - следующий день восстановление',
  pkgOpenEnvCountTips: 'Примечание: Максимум {maxCount} новых устройств в месяц. Превышение отключает добавление до следующего месяца.',
  freePkgTips:
    'Примечание: Каждый пользователь может использовать 6 часов бесплатно, за превышение этого времени взимается дополнительная плата!',
  shouldPay: 'Сумма к оплате: ',
  pkgCommentsLabel1: 'Формула расчета стоимости:',
  pkgCommentsValue1: '(Стоимость нового пакета - Стоимость старого пакета) / 31 * оставшиеся дни',
  pkgCommentsLabel2: 'Примечание:',
  pkgCommentsValue2:
    'При изменении пакета на пакет с меньшими возможностями система не производит возврат средств. Пожалуйста, будьте внимательны.',
  usersCommentsLabel: 'Увеличение количества пользователей x Стоимость / 31 x оставшиеся дни',
  usersCommentsValue:
    'При изменении пакета на пакет с меньшими возможностями или при уменьшении количества пользователей система не производит возврат средств. Пожалуйста, будьте внимательны.',
  changeConfirm: `
             <p class="text-red text-bold">После снижения тарифного плана:</p>
             <p class="text-red q-mt-sm">1. Все учетные записи сотрудников будут отключены. Пожалуйста, снова включите их в разделе "Управление сотрудниками"!</p>
             <p class="text-red q-mt-sm">2. Система не возвращает средства и изменения вступают в силу немедленно!</p>
             <p class="text-red q-mt-sm">3. Окна, превышающие количество тарифного плана, не будут удалены, но они будут заблокированы и невозможно будет их открыть!</p>
             <p class="q-mt-sm">Вы хотите продолжить?</p>
              `,
  environmentChangeConfirm: `Количество профилей телефона после понижения подписки меньше текущего числа созданных профилей телефона. Невозможно понизить план. Пожалуйста, следуйте инструкциям ниже, чтобы отключить избыточные профили телефона и попробуйте снова!
   <p class="q-mt-sm text-bold"> Профили телефона -> Больше -> Отключить профили массово</p>
  `,
  goDisableProfile: 'Отключить Профили',
  activateSuccess: 'Успешно открыт тарифный план',
  changeSuccess: 'Успешно изменен тарифный план',
  calculateError: 'Ошибка расчета суммы, повторите попытку',
  couponDialog: 'Купон',
  myCoupon: 'Мои купоны',
  usedCoupon: 'Использованные купоны',
  couponType: 'Тип купона',
  couponStatus: 'Статус купона',
  couponCode: 'Код купона',
  couponRemark: 'Комментарий',
  cashCoupon: 'Денежный купон',
  discountCoupon: 'Скидочный купон',
  couponStatus0: 'Не использован',
  couponStatus1: 'Использован',
  couponValue: 'Стоимость купона',
  couponValueFormat: '{value} юаней',
  couponCreatedTime: 'Дата выпуска',
  couponExpired: 'Срок',
  couponState: 'Состояние',
  couponExpense: 'Использовать купон',
  couponExpensePlaceholder: 'Введите код купона',
  changeUsersDialog: 'Изменить дополнительных сотрудников',
  additionalUsers: 'Дополнительные',
  assignedUsers: 'Использовано',
  totalUsers: 'Всего',
  increase: 'Увеличить',
  reduce: 'Уменьшить',
  userCost: 'Стоимость:',
  userCostUnit: '/чел./мес.',
  extraUsers: 'Исходных дополнительных сотрудников:',
  extraUsers2: 'чел., в этот раз',
  reduceTips:
    'Внимание: При уменьшении количества сотрудников, если оставшееся количество сотрудников (общее количество - использованные) меньше указанного количества для уменьшения, то после изменения аккаунты сотрудников будут отключены. Активируйте их снова в разделе "Управление сотрудниками".',
  totalExtraUsers: 'Общее количество сотрудников:',
  usersRule1: 'Введите целое число больше или равное нулю',
  usersRule2: 'Введите целое число меньше 10000',
  usersRule3: 'Число сокращения не может превышать число исходных дополнительных сотрудников',
  reduceUsersConfirm:
    'При уменьшении количества дополнительных сотрудников система не возвращает деньги и изменения вступают в силу немедленно. Пожалуйста, будьте внимательны! <br/>Хотите продолжить?',
  changeUsersSuccess: 'Успешно изменено количество дополнительных сотрудников',
  originalPrice: 'Исходная цена',
  finalPay: 'Фактическая оплата',
  cycleMonth: 'месяц',
  cycleMonths: 'месяцев',
  confirmActivate: 'Подтвердить открытие',
  confirmChange: 'Подтвердить изменение',
  confirm: 'Подтвердить',
  orderCreatedTime: 'Время заказа',
  orderMoney: 'Сумма заказа',
  orderState: 'Статус заказа',
  notPay: 'Не оплачен',
  hasPaid: 'Оплачен',
  rechargePlaceholder: 'Пожалуйста, заполните сумму',
  rechargeAmount: 'Сумма',
  minAmount: 'Минимальная сумма пополнения: {amount}',
  rechargeRule1: 'Пожалуйста, введите сумму. Сумма должна быть числом.',
  rechargeRule2: 'Минимальная сумма пополнения составляет',
  rechargeRule3: 'Минимальная единица суммы пополнения - фэнь',
  pkgDetail: 'Детали пакета',
  extraAddUsers: 'Дополнительные сотрудники: {count} чел.',
  renewCycle: 'Цикл продления оплаты',
  youhui: 'Скидка: ',
  renewExpired: 'Дата окончания после продления: ',
  renewComments: '(Стоимость текущего пакета + стоимость дополнительных сотрудников) x количество месяцев',
  renewEnvironmentComments: 'Стоимость текущего пакета x количество месяцев',
  confirmRenew: 'Подтвердить продление',
  renewSuccess: 'Успешно продлено',
  orderType: 'Тип заказа',
  orderType1: 'Пополнение',
  orderType31: 'Активация пакета',
  orderType2: 'Активация пакета',
  orderType3: 'Изменение пакета',
  orderType33: 'Продление пакета',
  orderType20: 'Купить облачный телефон',
  orderType22: 'Ежемесячные вычислительные мощности',
  orderType23: 'Ежемесячные вычислительные мощности',
  orderType21: 'Продление облачного телефона',
  orderType4: 'Продление пакета',
  orderType32: 'Продление пакета',
  orderType8: 'Изменение сотрудников',
  orderType9: 'Возврат и пополнение',
  orderType34: 'Временные вычислительные мощности',
  orderType34FreeTimeRmark: '№: {seq}, Потребленное время: {minutes} минут',
  orderType34Rmark: 'Временные вычислительные мощности, Сумма:{money}',
  orderType22Rmark: 'Купить месячную мощность,  {type}, {name}, {num} единиц, на {month} месяц(ев)',
  orderType23Rmark: 'Продление мощности: {name}, Сумма: {money}, Месяцы: {month}',
  cycle: 'Цикл',
  pkg: 'Пакет',
  activatePackage: 'Пакет {pkg}, цикл: 1 месяц',
  freePackageMsg: '{pkg}, цикл: {cycle} небо',
  activatePackageMonths: 'Пакет {pkg}, цикл: {cycle} месяцев',
  changePlan: 'Предыдущий пакет: {before}, новый пакет: {after}',
  renewDetail: 'Пакет: {pkg}, цикл: {months} месяцев',
  increaseUsers: 'Добавленные сотрудники: {count}',
  reduceUsers: 'Уменьшенные сотрудники: {count}',
  autoRenew: 'Автопродление',
  enableAutoRenew:
    'Вы уверены, что хотите включить aвтопродление?<p class="q-mt-sm">При включении aвтопродление система будет автоматически проверять наличие достаточного баланса на вашем аккаунте за 3 дня до окончания пакета. Если баланс достаточный, система автоматически продлит пакет на 1 месяц и списывает соответствующую сумму с вашего баланса!</p>',
  disableAutoRenew:
    'Вы уверены, что хотите отключить aвтопродление?<p class="q-mt-sm">При отключении aвтопродление система перестанет проверять окончание пакета и не будет автоматически продлевать его!</p>',
  rate: 'Комиссия:',
  bankCard: 'Банковская карта',
  ratioRule1: 'Не менее стандартного соотношения  системы',
  ratioRule2: 'Не выше вашей комиссии',
  payWarning:
    '<p class="text-red">Внимание: Используйте этот способ оплаты в соответствии с правилами. В случае обнаружения мошеннических действий или других рисковых платежей, ваш аккаунт будет навсегда заблокирован и не может быть разблокирован.</p>\n  <p class="text-red q-mt-sm">У нас есть строгие меры контроля рисков. Как только CVV будет украден, учетная запись будет заблокирована навсегда, и восстановить данные будет невозможно!< / p>',
  usedTimes: 'Использовано {arg} раз.',
  usedDate: 'Дата использования',
  orderNum: 'Номер заказа',
  remainingTimes: 'Остаток',
  Tobepaid: 'Подлежит оплате',
  chinIpbuyTip: 'IP относится к региону материкового Китая, этот способ оплаты не поддерживается!',
  discountCoupons: 'На данный товар разрешены только купоны на сумму, купоны на скидку запрещены!',
  discount: 'Скидочный купон: ',
  buyingCycle: 'Цикл покупки',
  buyTime: 'Продолжительность',
  buyNum: 'Количество',
  enterBuyNum: 'Введите кол-во',
  airwallexTip:
    'Оплата банковской картой поддерживается только для сумм от $10 и выше. Пожалуйста, пополните свой счет на $10 перед использованием баланса для оплаты.',
  browserBill: 'Оконный счет',
  computingBill: 'Счёт за облачный телефон',
  phoneComputingPower: 'Мобильная вычислительная мощность',
  monthlyComputing: 'Месячная вычислительная мощность: ',
  todaynewPhone: 'Сегодня мгновенные установки телефонов',
  todaynewPhoneTip:
    'Максимальное количество установок в день = Общее количество сред x {num}. Превышение блокирует установки на текущий день; сброс на следующий день.',
  monthAdditionalComputing: 'Расходы на мощность за месяц: ',
  phoneEnvironment: 'Счет за мобильную среду',
  environmentNum: 'Количество окружений:',
  detail: 'Подробнее',
  planName: 'План {name}',
  timeFree: 'Бесплатно',
  remainingFreeTime: 'Бесплатное время: '
}
