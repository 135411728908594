export const USER_TIME_ZONE_LIST = [
  {
    utcOffset: '(UTC-12:00)',
    name: 'International Date Line West',
    iana: 'Etc/GMT+12'
  },
  {
    utcOffset: '(UTC-11:00)',
    name: 'Coordinated Universal Time-11',
    iana: 'Etc/GMT+11'
  },
  {
    utcOffset: '(UTC-10:00)',
    iana: 'America/Adak',
    name: 'Aleutian Islands'
  },
  {
    utcOffset: '(UTC-10:00)',
    iana: 'Pacific/Honolulu',
    name: 'Hawaii'
  },
  {
    utcOffset: '(UTC-09:30)',
    iana: 'Pacific/Marquesas',
    name: 'Marquesas Islands'
  },
  {
    utcOffset: '(UTC-09:00)',
    iana: 'America/Anchorage',
    name: 'Alaska'
  },
  {
    utcOffset: '(UTC-09:00)',
    iana: 'Etc/GMT+9',
    name: 'Coordinated Universal Time-09'
  },
  {
    utcOffset: '(UTC-08:00)',
    iana: 'America/Tijuana',
    name: 'Baja California'
  },
  {
    utcOffset: '(UTC-08:00)',
    iana: 'Etc/GMT+8',
    name: 'Coordinated Universal Time-08'
  },
  {
    utcOffset: '(UTC-08:00)',
    iana: 'America/Los_Angeles',
    name: 'Pacific Time (US & Canada)'
  },
  {
    utcOffset: '(UTC-07:00)',
    iana: 'America/Phoenix',
    name: 'Arizona'
  },
  {
    utcOffset: '(UTC-07:00)',
    iana: 'America/Chihuahua',
    name: 'La Paz, Mazatlan'
  },
  {
    utcOffset: '(UTC-07:00)',
    iana: 'America/Denver',
    name: 'Mountain Time (US & Canada)'
  },
  {
    utcOffset: '(UTC-07:00)',
    iana: 'America/Whitehorse',
    name: 'Yukon'
  },
  {
    utcOffset: '(UTC-06:00)',
    iana: 'America/Guatemala',
    name: 'Central America'
  },
  {
    utcOffset: '(UTC-06:00)',
    iana: 'America/Chicago',
    name: 'Central Time (US & Canada)'
  },
  {
    utcOffset: '(UTC-06:00)',
    iana: 'Pacific/Easter',
    name: 'Easter Island'
  },
  {
    utcOffset: '(UTC-06:00)',
    iana: 'America/Mexico_City',
    name: 'Guadalajara, Mexico City, Monterrey'
  },
  {
    utcOffset: '(UTC-06:00)',
    iana: 'America/Regina',
    name: 'Saskatchewan'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/Jamaica',
    name: 'Bogota, Lima, Quito, Rio Branco'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/Cancun',
    name: 'Chetumal'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/New_York',
    name: 'Eastern Time (US & Canada)'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/Port-au-Prince',
    name: 'Haiti'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/Havana',
    name: 'Havana'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/Indianapolis',
    name: 'Indiana (East)'
  },
  {
    utcOffset: '(UTC-05:00)',
    iana: 'America/Grand_Turk',
    name: 'Turks and Caicos'
  },
  {
    utcOffset: '(UTC-04:00)',
    iana: 'America/Asuncion',
    name: 'Asuncion'
  },
  {
    utcOffset: '(UTC-04:00)',
    iana: 'America/Halifax',
    name: 'Atlantic Time (Canada)'
  },
  {
    utcOffset: '(UTC-04:00)',
    iana: 'America/Caracas',
    name: 'Caracas'
  },
  {
    utcOffset: '(UTC-04:00)',
    iana: 'America/Cuiaba',
    name: 'Cuiaba'
  },
  {
    utcOffset: '(UTC-04:00)',
    iana: 'America/La_Paz',
    name: 'Georgetown, La Paz, Manaus, San Juan'
  },
  {
    utcOffset: '(UTC-04:00)',
    iana: 'America/Santiago',
    name: 'Santiago'
  },
  {
    utcOffset: '(UTC-03:30)',
    iana: 'America/St_Johns',
    name: 'Newfoundland'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Araguaina',
    name: 'Araguaina'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Sao_Paulo',
    name: 'Brasilia'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Cayenne',
    name: 'Cayenne, Fortaleza'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Buenos_Aires',
    name: 'City of Buenos Aires'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Montevideo',
    name: 'Montevideo'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Punta_Arenas',
    name: 'Punta Arenas'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Miquelon',
    name: 'Saint Pierre and Miquelon'
  },
  {
    utcOffset: '(UTC-03:00)',
    iana: 'America/Bahia',
    name: 'Salvador'
  },
  {
    utcOffset: '(UTC-02:00)',
    iana: 'Etc/GMT+2',
    name: 'Coordinated Universal Time-02'
  },
  {
    utcOffset: '(UTC-02:00)',
    iana: 'America/Godthab',
    name: 'Greenland'
  },
  {
    utcOffset: '(UTC-01:00)',
    iana: 'Atlantic/Azores',
    name: 'Azores'
  },
  {
    utcOffset: '(UTC-01:00)',
    iana: 'Atlantic/Cape_Verde',
    name: 'Cabo Verde Is.'
  },
  {
    utcOffset: '(UTC)',
    iana: 'UTC',
    name: ' Coordinated Universal Time'
  },
  {
    utcOffset: '(UTC+00:00)',
    iana: 'Europe/London',
    name: 'Dublin, Edinburgh, Lisbon, London'
  },
  {
    utcOffset: '(UTC+00:00)',
    iana: 'Atlantic/Reykjavik',
    name: 'Monrovia, Reykjavik'
  },
  {
    utcOffset: '(UTC+00:00)',
    iana: 'Africa/Sao_Tome',
    name: 'Sao Tome'
  },
  {
    utcOffset: '(UTC+01:00)',
    iana: 'Africa/Casablanca',
    name: 'Casablanca'
  },
  {
    utcOffset: '(UTC+01:00)',
    iana: 'Europe/Berlin',
    name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  },
  {
    utcOffset: '(UTC+01:00)',
    iana: 'Europe/Budapest',
    name: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  },
  {
    utcOffset: '(UTC+01:00)',
    iana: 'Europe/Paris',
    name: 'Brussels, Copenhagen, Madrid, Paris'
  },
  {
    utcOffset: '(UTC+01:00)',
    iana: 'Europe/Warsaw',
    name: 'Sarajevo, Skopje, Warsaw, Zagreb'
  },
  {
    utcOffset: '(UTC+01:00)',
    iana: 'Africa/Lagos',
    name: 'West Central Africa'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Europe/Bucharest',
    name: 'Athens, Bucharest'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Asia/Beirut',
    name: 'Beirut'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Africa/Cairo',
    name: 'Cairo'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Europe/Chisinau',
    name: 'Chisinau'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Asia/Hebron',
    name: 'Gaza, Hebron'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Africa/Johannesburg',
    name: 'Harare, Pretoria'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Europe/Kiev',
    name: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Asia/Jerusalem',
    name: 'Jerusalem'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Africa/Juba',
    name: 'Juba'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Europe/Kaliningrad',
    name: 'Kaliningrad'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Africa/Khartoum',
    name: 'Khartoum'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Africa/Tripoli',
    name: 'Tripoli'
  },
  {
    utcOffset: '(UTC+02:00)',
    iana: 'Africa/Windhoek',
    name: 'Windhoek'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Asia/Amman',
    name: 'Amman'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Asia/Baghdad',
    name: 'Baghdad'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Asia/Damascus',
    name: 'Damascus'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Europe/Istanbul',
    name: 'Istanbul'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Asia/Riyadh',
    name: 'Kuwait, Riyadh'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Europe/Minsk',
    name: 'Minsk'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Europe/Moscow',
    name: 'Moscow, St. Petersburg'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Africa/Nairobi',
    name: 'Nairobi'
  },
  {
    utcOffset: '(UTC+03:00)',
    iana: 'Europe/Volgograd',
    name: 'Volgograd'
  },
  {
    utcOffset: '(UTC+03:30)',
    iana: 'Asia/Tehran',
    name: 'Tehran'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Asia/Dubai',
    name: 'Abu Dhabi, Muscat'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Europe/Astrakhan',
    name: 'Astrakhan, Ulyanovsk'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Asia/Baku',
    name: 'Baku'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Europe/Samara',
    name: 'Izhevsk, Samara'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Indian/Mauritius',
    name: 'Port Louis'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Europe/Saratov',
    name: 'Saratov'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Asia/Tbilisi',
    name: 'Tbilisi'
  },
  {
    utcOffset: '(UTC+04:00)',
    iana: 'Asia/Yerevan',
    name: 'Yerevan'
  },
  {
    utcOffset: '(UTC+04:30)',
    iana: 'Asia/Kabul',
    name: 'Kabul'
  },
  {
    utcOffset: '(UTC+05:00)',
    iana: 'Asia/Tashkent',
    name: 'Ashgabat, Tashkent'
  },
  {
    utcOffset: '(UTC+05:00)',
    iana: 'Asia/Yekaterinburg',
    name: 'Ekaterinburg'
  },
  {
    utcOffset: '(UTC+05:00)',
    iana: 'Asia/Karachi',
    name: 'Islamabad, Karachi'
  },
  {
    utcOffset: '(UTC+05:00)',
    iana: 'Asia/Qyzylorda',
    name: 'Qyzylorda'
  },
  {
    utcOffset: '(UTC+05:30)',
    iana: 'Asia/Calcutta',
    name: 'Chennai, Kolkata, Mumbai, New Delhi'
  },
  {
    utcOffset: '(UTC+05:30)',
    iana: 'Asia/Colombo',
    name: 'Sri Jayawardenepura'
  },
  {
    utcOffset: '(UTC+05:45)',
    iana: 'Asia/Katmandu',
    name: 'Kathmandu'
  },
  {
    utcOffset: '(UTC+06:00)',
    iana: 'Asia/Almaty',
    name: 'Astana'
  },
  {
    utcOffset: '(UTC+06:00)',
    iana: 'Asia/Dhaka',
    name: 'Dhaka'
  },
  {
    utcOffset: '(UTC+06:00)',
    iana: 'Asia/Omsk',
    name: 'Omsk'
  },
  {
    utcOffset: '(UTC+06:30)',
    iana: 'Asia/Rangoon',
    name: 'Yangon (Rangoon)'
  },
  {
    utcOffset: '(UTC+07:00)',
    iana: 'Asia/Bangkok',
    name: 'Bangkok, Hanoi, Jakarta'
  },
  {
    utcOffset: '(UTC+07:00)',
    iana: 'Asia/Barnaul',
    name: 'Barnaul, Gorno-Altaysk'
  },
  {
    utcOffset: '(UTC+07:00)',
    iana: 'Asia/Hovd',
    name: 'Hovd'
  },
  {
    utcOffset: '(UTC+07:00)',
    iana: 'Asia/Krasnoyarsk',
    name: 'Krasnoyarsk'
  },
  {
    utcOffset: '(UTC+07:00)',
    iana: 'Asia/Novosibirsk',
    name: 'Novosibirsk'
  },
  {
    utcOffset: '(UTC+07:00)',
    iana: 'Asia/Tomsk',
    name: 'Tomsk'
  },
  {
    utcOffset: '(UTC+08:00)',
    iana: 'Asia/Shanghai',
    name: 'Beijing, Chongqing, Hong Kong, Urumqi'
  },
  {
    utcOffset: '(UTC+08:00)',
    iana: 'Asia/Irkutsk',
    name: 'Irkutsk'
  },
  {
    utcOffset: '(UTC+08:00)',
    iana: 'Asia/Singapore',
    name: 'Kuala Lumpur, Singapore'
  },
  {
    utcOffset: '(UTC+08:00)',
    iana: 'Australia/Perth',
    name: 'Perth'
  },
  {
    utcOffset: '(UTC+08:00)',
    iana: 'Asia/Taipei',
    name: 'Taipei'
  },
  {
    utcOffset: '(UTC+08:00)',
    iana: 'Asia/Ulaanbaatar',
    name: 'Ulaanbaatar'
  },
  {
    utcOffset: '(UTC+08:45)',
    iana: 'Australia/Eucla',
    name: 'Eucla'
  },
  {
    utcOffset: '(UTC+09:00)',
    iana: 'Asia/Chita',
    name: 'Chita'
  },
  {
    utcOffset: '(UTC+09:00)',
    iana: 'Asia/Tokyo',
    name: 'Osaka, Sapporo, Tokyo'
  },
  {
    utcOffset: '(UTC+09:00)',
    iana: 'Asia/Pyongyang',
    name: 'Pyongyang'
  },
  {
    utcOffset: '(UTC+09:00)',
    iana: 'Asia/Seoul',
    name: 'Seoul'
  },
  {
    utcOffset: '(UTC+09:00)',
    iana: 'Asia/Yakutsk',
    name: 'Yakutsk'
  },
  {
    utcOffset: '(UTC+09:30)',
    iana: 'Australia/Adelaide',
    name: 'Adelaide'
  },
  {
    utcOffset: '(UTC+09:30)',
    iana: 'Australia/Darwin',
    name: 'Darwin'
  },
  {
    utcOffset: '(UTC+10:00)',
    iana: 'Australia/Brisbane',
    name: 'Brisbane'
  },
  {
    utcOffset: '(UTC+10:00)',
    iana: 'Australia/Sydney',
    name: 'Canberra, Melbourne, Sydney'
  },
  {
    utcOffset: '(UTC+10:00)',
    iana: 'Pacific/Port_Moresby',
    name: 'Guam, Port Moresby'
  },
  {
    utcOffset: '(UTC+10:00)',
    iana: 'Australia/Hobart',
    name: 'Hobart'
  },
  {
    utcOffset: '(UTC+10:00)',
    iana: 'Asia/Vladivostok',
    name: 'Vladivostok'
  },
  {
    utcOffset: '(UTC+10:30)',
    iana: 'Australia/Lord_Howe',
    name: 'Lord Howe Island'
  },
  {
    utcOffset: '(UTC+11:00)',
    iana: 'Pacific/Bougainville',
    name: 'Bougainville Island'
  },
  {
    utcOffset: '(UTC+11:00)',
    iana: 'Asia/Srednekolymsk',
    name: 'Chokurdakh'
  },
  {
    utcOffset: '(UTC+11:00)',
    iana: 'Asia/Magadan',
    name: 'Magadan'
  },
  {
    utcOffset: '(UTC+11:00)',
    iana: 'Pacific/Norfolk',
    name: 'Norfolk Island'
  },
  {
    utcOffset: '(UTC+11:00)',
    iana: 'Asia/Sakhalin',
    name: 'Sakhalin'
  },
  {
    utcOffset: '(UTC+11:00)',
    iana: 'Pacific/Guadalcanal',
    name: 'Solomon Is., New Caledonia'
  },
  {
    utcOffset: '(UTC+12:00)',
    iana: 'Asia/Kamchatka',
    name: 'Anadyr, Petropavlovsk-Kamchatsky'
  },
  {
    utcOffset: '(UTC+12:00)',
    iana: 'Pacific/Auckland',
    name: 'Auckland, Wellington'
  },
  {
    utcOffset: '(UTC+12:00)',
    iana: 'Etc/GMT-12',
    name: 'Coordinated Universal Time+12'
  },
  {
    utcOffset: '(UTC+12:00)',
    iana: 'Pacific/Fiji',
    name: 'Fiji'
  },
  {
    utcOffset: '(UTC+12:45)',
    iana: 'Pacific/Chatham',
    name: 'Chatham Islands'
  },
  {
    utcOffset: '(UTC+13:00)',
    iana: 'Etc/GMT-13',
    name: ' Coordinated Universal Time+13'
  },
  {
    utcOffset: '(UTC+13:00)',
    iana: 'Pacific/Tongatapu',
    name: "Nuku'alofa"
  },
  {
    utcOffset: '(UTC+13:00)',
    iana: 'Pacific/Apia',
    name: 'Samoa'
  },
  {
    utcOffset: '(UTC+14:00)',
    iana: 'Pacific/Kiritimati',
    name: 'Kiritimati Island'
  }
]
