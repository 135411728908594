export default {
  baseinfo: '基本信息',
  name: '姓名',
  username: '用户名',
  email: '邮箱',
  bindEmail: '绑定邮箱',
  qqPlaceholder: '请输入微信/QQ',
  qq: '微信/QQ',
  save: '保存信息',
  changeTel: '更换手机号',
  changeEmail: '更换邮箱',
  currentTel: '当前手机号码',
  currentEmail: '当前邮箱',
  newEmail: '新邮箱',
  codePlaceholder: '输入编码搜索',
  newTel: '新手机号码',
  telPlaceholder: '请输入手机号码',
  verifyCodePlaceholder: '请输入发送到您手机上的验证码',
  verifyEmailPlaceholder: '请输入发送到您邮箱的验证码',
  emailverifyCode: '请输入邮箱验证码',
  verifyCode: '验证码',
  checkType: '校验类型',
  passwordPlaceholder: '请输入登录密码',
  password: '登录密码',
  confirmChange: '确认更换',
  passwordSet: '密码设置',
  oldPassPlaceholder: '请输入原始密码',
  oldPassword: '原密码',
  newPassPlaceholder: '请输入新密码',
  newPass: '新密码',
  newPassAgain: '请再次输入新密码',
  confirmPassword: '确认密码',
  confirmPassword1: '确认密码',
  savepass: '修改密码',
  passRule1: '请再次输入新密码',
  passRule2: '两次输入密码不一致',
  nationRule: '请选择国家',
  resentTips: '{seconds}秒后重新发送',
  send: '发送验证码',
  qqChangeConfirm: '确定修改邮箱吗？',
  passChangeConfirm: '确定修改密码吗？<br/>修改密码后请使用新密码登录！',
  passChangeSuccess: '密码修改成功',
  telChangeConfirm: '确定绑定此手机号码吗？',
  telChangeSuccess: '手机号码修改成功',
  telChangeError: '当前手机号码已被其他账号绑定，请更换手机号码！',
  emailChangeError: '当前邮箱已被其他账号绑定，请更换邮箱！',
  sendSuccess: '发送成功',
  forgetUsername: '找回用户名',
  findAccount: '找回用户名',
  yourUsername: '您的用户名',
  getBack: '立即找回',
  welcome: 'Hi，欢迎登录',
  backLogin: '返回登录',
  verifyRule1: '请输入动态验证码',
  verifyRule2: '验证码为6位数字',
  usernameCopied: '{name}已复制到剪贴板',
  usernamePlaceholder: '请输入用户名',
  usernamePlaceholder1: '请输入用户名/手机号/邮箱',
  loginPassPlaceholder: '请输入密码',
  loginPassPlaceholder1: '请输入登录密码',
  loginPass: '密码',
  rememberPass: '记住密码',
  forgetPass: '忘记密码',
  login: '登录',
  toRegister: '还没有账号？去注册',
  signingIn: '正在登录...',
  welcomeRegister: 'Hi，欢迎注册',
  registerUsername: '请输入用户名 a~z 0~9',
  tel: '手机号码',
  registerQQPlaceholder: '请输入微信/QQ（选填）',
  registerEmail: '请输入邮箱（选填）',
  inviteCodePlaceholder: '请输入邀请码（选填）',
  inviteCode: '邀请码',
  register: '注册',
  readAndKnow: '已阅读并同意',
  userPolicy: '《用户服务协议》',
  haveAccount: '已有账号？',
  toLogin: '去登录',
  userPolicyDialog: '用户服务协议',
  registerUsernameRule: '用户名最少6位',
  inviteCodeRule: '邀请码最长不超过10位',
  policyValid: '请阅读并同意《用户服务协议》',
  registerSuccess: '注册成功，是否立即下载软件客户端？',
  downloadNow: '立即下载',
  downloadLater: '稍后下载',
  registerAndLogin: '注册成功，请登录',
  resetPass: '重置密码',
  resetConfirm: '确认修改',
  resetSuccess: '密码重置成功',
  '2fAuth': '登录双重验证',
  otherSetting: '密码验证',
  opendeleteWindowvVer: '开启彻底删除窗口/环境密码验证',
  enable2fa: '开启双重验证',
  enableConfirm:
    '确认开启双重验证吗？<p class="q-mt-sm">开启后，您在登录软件时将需要通过登录双重验证。</p><p class="q-mt-sm text-red">注意：请确保您当前手机号码可以正常接收短信，以防止因无法接收短信而导致无法通过双重验证！</p>',
  disable2faConfirm: '确认关闭双重验证吗？<p class="q-mt-sm">关闭后，您仅需要使用用户名密码即可登录。</p>',
  enablePasswordVerification:
    '确认开启彻底删除窗口密码验证吗？<p class="q-mt-sm text-red">开启后，在删除浏览器窗口时将需要进行密码验证。</p>',
  disablePasswordVerification:
    '确认关闭彻底删除窗口密码验证吗？<p class="q-mt-sm text-red">关闭后，在删除浏览器窗口时不需要输入密码进行验证。</p>',
  openTransBrowserPassword: '开启转移/分享窗口密码验证',
  enablePasswordVerification1:
    '确认开启转移/分享窗口密码验证吗？<p class="q-mt-sm text-red">开启后，在转移/分享浏览器窗口时将需要进行密码验证。</p>',
  disablePasswordVerification1:
    '确认关闭转移/分享窗口密码验证吗？<p class="q-mt-sm text-red">关闭后，在转移/分享浏览器窗口时不需要输入密码进行验证。</p>',
  authenticator: '验证器',
  bind: '绑定',
  unbind: '解绑',
  bound: '已绑定',
  secretKey: '验证器密钥',
  googleAuthCode: '验证器验证码',
  googleCodePlaceholder: '请输入验证器生成的验证码',
  bindTitle: '绑定验证器',
  scanQrCode: '扫描二维码',
  authValidation: '验证绑定',
  scanTips: '温馨提示：使用验证器扫描下方二维码或者复制密钥使用。',
  unbindTitle: '解绑验证器',
  recommended: '推荐使用的验证器',
  googleAuthenticator: '谷歌验证器',
  microsoftAuthenticator: '微软验证器',
  tencentAuthenticator: '腾讯验证器',
  sms: '短信验证',
  validationMethod: '验证方式',
  smsCode: '手机验证码',
  emailCode: '邮箱验证码',
  requiredEmail: '请输入邮箱',
  emailPlaceholder: '请输入邮箱',
  emailVerification: '邮箱验证',
  emailRegister: '邮箱注册',
  phoneRegister: '手机注册',
  phoneVerification: '手机验证',
  '2fAuthTips': '请开通套餐以启用此功能',
  vistualTelTips:
    '使用虚拟手机号码注册的账号，有被盗风险，在登录到系统时将会触发安全验证，请确保此号码可随时接收到短信验证码；强烈建议使用实体手机号码注册账号。',
  secureAuth: '身份安全验证',
  selectSymbol: '请选择结算币种',
  SettlementCurrency: '结算币种',
  SettlementCurrencyMsg: '结算所使用的币种，注册后无法修改，请谨慎选择！',
  securitySetting: '安全设置',
  unbound: '未绑定',
  emailAuth: '邮箱认证',
  bindPhone: '绑定手机号',
  telAuthenticationTitle: '认证手机号',
  accreditation: '认证',
  passwordLogin: '密码登录',
  verificationLogin: '验证码登录',
  userNamephone: '用户名/手机/邮箱',
  phoneLoginTip: '填写手机号时，请勿输入区号',
  authenticated: '已认证',
  phoneEmailLoginTip: '注：可以直接使用已认证成功的手机号/邮箱登录{arg}。',
  toAuthen: '去认证',
  notCertified: '未认证',
  isFreeAccountBindTip: '注：开通套餐后支持绑定',
  verificationType: '认证类型',
  personalVer: '个人认证',
  enterpriseVer: '企业认证',
  AuthenticationName: '认证名称',
  detailAuth: '认证信息',
  upgradeEnterprise: '升级企业认证',
  changePassword: '原密码修改',
  changeVerificationCode: '手机/邮箱验证码修改',
  emailResitertip: '请勿使用临时邮箱注册账号，此类账号有无法登录的风险。',
  privacyPolicy: '《隐私协议》'
}
