import { LocalStorage, uid } from 'quasar'
import { getHash } from 'src/utils/jssha'

async function init() {
  if (!LocalStorage.has('Bit_T_XCF')) {
    LocalStorage.set('Bit_T_XCF', 'll' + getHash(uid()))
  }

  if (!window.isInBitBrowser || typeof window.bitClientMain.getPerformanceConfig !== 'function') {
    return
  }

  try {
    const res = await window.bitClientMain.getPerformanceConfig()

    // 还没有设置过默认值，则设置一次
    if (!res) {
      window.bitClientMain.savePerformanceConfig({
        open: true,
        cpu: 95,
        mem: 95,
        freeCacheSpace: 1,
        xcf: getHash(uid()) + Date.now()
      })
      return
    }

    // config里没有值的，也重新设置一次
    if (!res.xcf || res.xcf.length <= 40) {
      window.bitClientMain.savePerformanceConfig({
        ...res,
        xcf: getHash(uid()) + Date.now()
      })
    }
  } catch (err) {
    console.error('xcf init error ==>>>>> ', err)
  }
}

export async function getClientXCF() {
  try {
    if (window.isInBitBrowser && typeof window.bitClientMain.getPerformanceConfig === 'function') {
      const res = await window.bitClientMain.getPerformanceConfig()
      if (res && res.xcf) return res.xcf
    }
  } catch (err) {
    console.error('get xcf error ==>>>> ', err)
  }

  return LocalStorage.getItem('Bit_T_XCF') || 'rr' + getHash(uid())
}

// 如果没有值，则默认设置为自动检测
async function resetDefaultSysProxy() {
  if (!window.isInBitBrowser) return

  try {
    const res = await window.bitClientMain.getGlobalConfigs()
    if (!res.sys_proxy_config) {
      window.bitClientMain.saveSysProxyConfig(
        JSON.stringify({
          type: 1,
          port: null
        })
      )
    }
  } catch (err) {
    console.error(err)
  }
}

init()
resetDefaultSysProxy()
