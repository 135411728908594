import { ref } from 'vue'
import store from 'src/store'
import { USER_TIME_ZONE_LIST } from 'src/static-data/userTimeZone'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export function useTimezone() {
  const GMT_OFFSET_MAP = new Map()
  const list = USER_TIME_ZONE_LIST.map(item => {
    item.label = `${item.utcOffset} ${item.name}`
    item.value = item.iana
    item.offset = item.utcOffset
    GMT_OFFSET_MAP.set(item.gmt, item.offset)
    return item
  })
  const timezoneList = ref(list)
  return {
    timezoneList,
    GMT_OFFSET_MAP,
    timezoneFilter(val, update) {
      if (!val) {
        update(() => {
          timezoneList.value = USER_TIME_ZONE_LIST
        })
        return
      }
      update(() => {
        timezoneList.value = USER_TIME_ZONE_LIST.filter(item => item.label.toLowerCase().includes(val.toLowerCase()))
      })
    }
  }
}

/**
 * 将用户时区的时间转换为东八区时间
 * @param {string} timeStr - 输入的时间字符串（例如 "2025-01-01 00:00:00"）
 *  @param {targetTimeZone} 用户当前时区
 * @returns {string} - 转换后的东八区时间
 */
export function convertToEastEight(timeStr, targetTimeZone) {
  if (!timeStr) return timeStr
  if (!dayjs(timeStr).isValid()) return timeStr

  const sourceTime = dayjs.tz(timeStr, targetTimeZone)

  const targetTime = sourceTime.tz('Asia/Shanghai')

  return targetTime.format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 将东八时区时间转换为传入时区时间
 * @param {string} eastEightTimeStr - 输入的东八区时间字符串（格式：YYYY-MM-DD HH:mm:ss）
 * @param {string} targetTimeZone - 输入的时区（格式：Asia/Shanghai）
 * @param {displayDate} 'YYYY-MM-DD'
 * @returns {string} - 转换后的本地时间字符串（格式：YYYY-MM-DD HH:mm:ss）
 */

export function convertEastEightToLocal(eastEightTimeStr, targetTimeZone, displayDate) {
  if (!eastEightTimeStr) return
  if (!eastEightTimeStr.includes(':')) return eastEightTimeStr
  if (!dayjs(eastEightTimeStr).isValid()) return eastEightTimeStr

  // 要转换为东八区时间
  const eastEightTime = dayjs.tz(eastEightTimeStr, 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai')

  const localTime = targetTimeZone ? eastEightTime.tz(targetTimeZone) : eastEightTime.local()

  return localTime.format(displayDate ? displayDate : 'YYYY-MM-DD HH:mm:ss')
}

// 指定需要转换的 key
export const keysToConvert = [
  'createdTime',
  'updateTime',
  'closeTime',
  'operTime',
  'endTime',
  'userTime',
  'lastOpenTime',
  'createTime',
  'expireTime'
]

// 不需要转换时区的url
export const nonTransformableURLs = new Set(['/v1/sysDictionarys/list', '/v1/sysDictionarys/getChildrenListByName', '/users/currentUser'])

/**
 * 递归转换指定 key 的时间值为本地时区时间
 * @param {Object|Array} data - 后端返回的数据
 * @param {string[]} keys - 需要检查的 key 列表
 * @returns {Object|Array} 转换后的数据
 */
export function handleTimeZoneConversion(data, keys) {
  if (Array.isArray(data)) {
    return data.map(item => handleTimeZoneConversion(item, keys))
  }

  if (typeof data === 'object' && data !== null) {
    const result = {}
    for (const [key, value] of Object.entries(data)) {
      if (keys.includes(key) && typeof value === 'string') {
        const timezone = store.getters['user/timezone']

        result[key] = convertEastEightToLocal(value, timezone)
      } else if (typeof value === 'object' && value !== null) {
        result[key] = handleTimeZoneConversion(value, keys)
      } else {
        result[key] = value
      }
    }
    return result
  }

  return data
}

// 获取不同时区当前月的开始和结束日期
export function getFirstDayOfNextMonth(timezone) {
  const currentTimezone = timezone || dayjs.tz.guess() // 如果没有传入时区，使用系统时区

  const currentDate = dayjs.tz(dayjs(), currentTimezone)

  const firstDayOfNextMonth = currentDate.add(1, 'month').startOf('month')

  const lastDayOfMonth = firstDayOfNextMonth.subtract(1, 'day')

  // 格式化年月日
  const year = lastDayOfMonth.year()
  const month = String(lastDayOfMonth.month() + 1).padStart(2, '0')
  const day = String(lastDayOfMonth.date()).padStart(2, '0')

  const createTime = {
    from: `${year}-${month}-01`,
    to: `${year}-${month}-${day}`
  }

  return createTime
}

// 获取当前时区的偏移
export function getUtcOffsetFormatted() {
  const offsetMinutes = new Date().getTimezoneOffset()

  const absoluteMinutes = Math.abs(offsetMinutes)
  const hours = Math.floor(absoluteMinutes / 60) // 小时部分
  const minutes = absoluteMinutes % 60 // 分钟部分
  const sign = offsetMinutes > 0 ? '-' : '+' // 正负号

  return `(UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')})`
}

// 查找整数的偏移
function normalizeUtcOffset(utcOffset) {
  // 使用正则解析 `(UTC±hh:mm)` 格式
  const match = utcOffset.match(/\(UTC([+-])(\d{2}):(\d{2})\)/)
  if (!match) {
    return null
  }

  const sign = match[1] // 正负号
  let hours = parseInt(match[2], 10) // 小时部分
  let minutes = parseInt(match[3], 10) // 分钟部分

  // 如果分钟数无法找到匹配，调整为整小时（即将分钟部分设置为 00）
  if (minutes !== '00') {
    minutes = 0 // 设置为整小时
  }

  // 确保小时符号和修正后的结果匹配
  const normalizedHours = sign === '-' ? -hours : hours

  // 格式化为标准 `(UTC±hh:mm)` 格式
  return `(UTC${normalizedHours >= 0 ? '+' : '-'}${String(Math.abs(normalizedHours)).padStart(2, '0')}:${String(minutes).padStart(2, '0')})`
}

// 获取当前时区
export function getLocalTimeZone() {
  // 如果有当前时区，就设置
  let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  localTimezone = USER_TIME_ZONE_LIST.find(item => item.iana === localTimezone)
  if (localTimezone) return localTimezone.iana

  // 如果没有 就找偏移一样的
  let utcOffset = getUtcOffsetFormatted()

  utcOffset = USER_TIME_ZONE_LIST.find(item => item.utcOffset === utcOffset)
  if (utcOffset) return utcOffset.iana

  // 如果还是找不到，就设置偏移为整数去查找

  return USER_TIME_ZONE_LIST.find(item => item.utcOffset === normalizeUtcOffset(utcOffset)) || USER_TIME_ZONE_LIST[0].iana
}

/**
 * 获取传入时区的当前时间
 * @param {string[]} timeZone - 当前时区
 * @returns {String[]} 当前时间
 */
export function getTimestampForZone(timeZone) {
  const localNow = dayjs()

  if (!timeZone) {
    return localNow.valueOf()
  }

  const zonedTime = localNow.tz(timeZone)
  return zonedTime.format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 获取指定时区的当前时间，n 个月后的时间
 * @param {string} [timeZone] - 传入的时区 (如 "Asia/Shanghai", "Etc/GMT+11")，默认为当前时区
 * @param {number} [months=0] - 增加的月份数 (默认 0 表示当前时间)
 * @param {displayDate} 'YYYY-MM-DD'
 * @param {inputTime} 2015-01-02 22:02:03 传入的时间是指定时区的时间
 * @returns {string} 格式化后的时间 'YYYY-MM-DD HH:mm:ss'
 */

export function getFutureTimeInTimeZone(timeZone, months = 0, displayDate, inputTime) {
  // 如果未传入时区，使用当前系统时区
  const targetTimeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone

  // 如果没有传入时间，则使用当前时区的当前时间
  const currentTime = inputTime ? dayjs(inputTime) : dayjs().tz(targetTimeZone)

  const futureTime = currentTime.add(months, 'month')

  return futureTime.format(displayDate ? displayDate : 'YYYY-MM-DD HH:mm:ss')
}

/**
 * 获取指定时区的当前时间，n 天后的时间
 * @param {string} [timeZone] - 传入的时区 (如 "Asia/Shanghai", "Etc/GMT+11")，默认为当前时区
 * @param {number} [day=0] - 增加的天数 (默认 0 表示当前时间)
 * @param {displayDate} 'YYYY-MM-DD'
 * @param {inputTime} 2015-01-02 22:02:03 传入的时间是指定时区的时间
 * @returns {string} 格式化后的时间 'YYYY-MM-DD HH:mm:ss'
 */
export function getFutureDaysInTimeZone(timeZone, day = 0, displayDate, inputTime) {
  // 如果未传入时区，使用当前系统时区
  const targetTimeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone

  // 如果没有传入时间，则使用当前时区的当前时间
  const currentTime = inputTime ? dayjs(inputTime) : dayjs().tz(targetTimeZone)

  const futureTime = currentTime.add(day, 'day')

  return futureTime.format(displayDate ? displayDate : 'YYYY-MM-DD HH:mm:ss')
}
