export default {
  category: '分类：',
  addExtension: '添加自定义扩展',
  addFromLocal: '添加本地扩展',
  addFromGoogle: '添加Chrome商店扩展',
  publishTips: '发布扩展插件到扩展中心，请联系客服。',
  publish: '发布到扩展中心',
  enable: '已启用',
  disable: '未启用',
  status: '启用状态：',
  name: '扩展名称：',
  name1: '扩展名称',
  userExtensions: '自定义扩展',
  unavailableExtension: '不可用扩展',
  unavailableExtensionTip: '（非您当前所属主账号上传）',
  noUserExtensions: '暂未添加任何扩展',
  extensions: '扩展中心',
  more: '查看更多',
  provider: '提供方：',
  notInClient: '请在客户端中操作',
  uploadExtension: '上传扩展',
  uploadTips:
    '温馨提示：标准的chrome扩展文件夹中，包含一个manifest.json文件，此文件中含有扩展名称与版本等信息，请选择manifest.json所在文件为根目录的文件夹。',
  checkDir: '点此选择扩展，自动识别扩展信息',
  pickDir: '请选择扩展目录文件夹',
  extensionDir: '扩展文件目录',
  versionPlaceholder: '自动识别扩展版本',
  version: '扩展版本',
  icon: '扩展ICON',
  iconTips: '请选择正方形图片，大小不超过50KB',
  extensionDesc: '扩展描述',
  googleStore: '识别Chrome商店扩展',
  googleStoreTips: '温馨提示：输入Google应用商店指定扩展的详情页面URL，自动识别并下载扩展',
  extensionUrlPlaceholder: '请输入扩展详情URL',
  extensionUrl: '扩展详情URL',
  detect: '识别',
  nameRuleMsg: '请输入扩展名称',
  descRuleMsg: '请输入扩展描述',
  pathRuleMsg: '请点击上方按钮选择扩展目录',
  disableExtension: '确认要为所有浏览器移除此扩展吗？',
  enableExtension: '确认启用此扩展吗？',
  syncSuccess: '同步成功',
  syncFailed: '同步失败，请重试',
  noManifest: '目录中不存在manifest.json文件，请重新选择',
  saveSuccess: '保存成功',
  deleteExtension: '确定要删除本地扩展吗？',
  toggleExtensEetection: '非此主账号下扩展禁止设置',
  deleteSuccess: '删除成功',
  detectFailed: '检测失败，请确保本地网络开启了全局代理模式',
  updateFailed: '更新失败，请确保本地网络开启了全局代理模式',
  saveFailed: '请识别正确后再进行添加',
  '9PerPage': '9条/页',
  '18PerPage': '18条/页',
  '45PerPage': '45条/页',
  '90PerPage': '90条/页',
  upgradeTips: '请升级客户端后使用扩展功能！',
  deleteExtensionTips: '删除扩展',
  upgradeExtensionTips: '更新扩展',
  updateLocalExtensionTips: '待更新扩展一定不要选择错误，否则会导致原扩展及扩展信息丢失！',
  noNeedUpdate: '当前已是最新版，无需更新！',
  cloudSync: '云端同步',
  uploadSuccess: '上传成功',
  uploadMaxSize: '选择的图片请不要超过50KB！',
  notImg: '请选择图片上传！',
  syncEnabled: '员工账号同步开启',
  syncEnabledConfirm: '确定要为所有员工账号同步此扩展的开启状态吗？',
  syncDisabled: '员工账号同步关闭',
  syncDisabledConfirm: '确定要为所有员工账号同步此扩展的关闭状态吗？',
  extensionExists: '扩展已存在',
  uploader: '上传人：',
  cannotDelete: '不是您上传扩展，无法删除！',
  allBrowser: '所有窗口',
  allBrowserDesc: '所有窗口均会开启此扩展',
  prescribedBrowser: '指定窗口',
  prescribedBrowserDesc: '需要手动选择指定窗口或分组开启此扩展',
  settingExtensionMethod: '设置扩展开启方式',
  extendsSetiingaVailable: '此功能只对6.0.4版本以上生效',
  setting: '设置',
  mainExtensionsTip: '已有此扩展，请选择更新扩展',
  noGoogleUrl: '请输入Chrome应用商店扩展程序详情页面对应的URL',
  googleUrlDetection: '检测失败，请输入Chrome应用商店扩展程序详情页面对应的URL',
  failedToGetExtension: '获取扩展失败，请稍后再试	'
}
