export default {
  fileManag: '文件管理',
  buyCloudPhone: '购买云手机',
  totalDevices: '总设备数：',
  batchOpenTelecast: '批量开启直播',
  goLive: '开启直播',
  endLive: '关闭直播',
  batchStartLive: '批量开启/关闭直播',
  batchEndLive: '批量关闭直播',
  batchUpdate: '批量修改',
  batchUpdateGroup: '批量修改分组',
  batchUpdateProxy: '批量修改代理',
  batchUpdateRemart: '批量修改备注',
  batchRenew: '批量续费算力',
  updateSelectSetting: '修改选中云手机所有配置',
  batchAutoRenew: '批量开启自动续费',
  batchAutoRenew1: '批量关闭自动续费',
  batchAutoRenew2: '批量开启/关闭自动续费',
  autoRenew1: '关闭自动续费',
  autoRenew2: '开启/关闭自动续费',
  autoRenew: '开启自动续费',
  autoRenewOpen: '已开启自动续费',
  sharePhone: '分享选中云手机与他人共用',
  sharePhone1: '分享云手机与他人共用',
  transformPhone: '转移选中云手机给他人',
  transformPhone1: '转移云手机给他人',
  batchDelect: '批量删除云手机',
  deletePhone: '删除',
  expirationTime: '到期时间',
  status: '在线状态',
  model: '机型',
  edit: '编辑云手机',
  phoneBrand: '手机品牌',
  equipmentModel: '设备型号',
  wifyName: 'WIFI名称',
  timingRules: '计时规则',
  customDeviceName: '自定义设备名称',
  '12hour': '12小时',
  '24hour': '24小时',
  wifyNameLimit: 'WIFI名字不能超过50字符',
  selectCountry: '请选择国家/地区',
  selectTimeRules: '请选择计时规则',
  selectNationCode: '请选择手机区号',
  enterWify: '请输入WIFI名称',
  selectPhone: '请选择云手机',
  updateConfig: '修改云手机配置',
  installApps: '安装应用',
  batchInstall: '批量安装',
  uninstallApp: '卸载应用',
  batchUninstall: '批量卸载',
  batchApp: '批量安装/卸载应用',
  renew: '续费',
  restart: '重启',
  batchRestart: '批量重启',
  screenshot: '执行截屏',
  batchScreenshot: '批量执行截屏',
  rootSwitch: '开启ROOT权限',
  batchOpenRoot: '批量开启ROOT权限',
  batchCloseRoot: '批量关闭ROOT权限',
  startStopLive: '开启/关闭直播',
  restoreFactory: '恢复出厂设置',
  batchRestoreFactory: '批量恢复出厂设置',
  cancelCommonly: '批量取消常用',
  setCommonly: '批量设置为常用',
  batchOpenClose: '批量打开/关闭',
  batchOpen: '批量打开',
  batchClose: '批量关闭',
  batchUpdateConfig: '批量修改云手机配置',
  batchUpdateTips: '需要对哪项进行修改，请勾选该项最左侧的勾选框，然后再修改该项配置值，届时所有云手机的该项配置值将同时修改为您设的！',
  area: '机房区域',
  renewDevice: '续费云手机',
  renewTime: '续费时长',
  renewPhoneTip1: '注意：请尽快完成支付，确保您所选的区域、机型、时长、数量、支付方式等信息填写无误，一旦下单则不能退换！',
  renewPhoneTip: '注意：请尽快完成支付，确保您所选的设备、数量、续费时长、支付方式等信息填写无误，一旦下单则不能退换！',
  order: '订单核对',
  buyMonth: '购买时长：',
  orderMoney: '订单总额：',
  payableAmount: '实际应付金额：',
  submitOrder: '提交订单',
  confirmPayment: '确认支付',
  price: '价格',
  selectedDevice: '已选设备：',
  fileUpload: '文件上传',
  fileDele: '文件删除',
  openChecked: '打开选中云手机',
  closeChecked: '批量关闭选中云手机',
  closeAll: '批量关闭所有云手机',
  deleteCompletely: '删除选中云手机',
  transformTitle: '转移云手机',
  selectModel: '选择机型',
  link: '链接',
  linking: '链接中',
  fileSize: '文件大小',
  uploadTime: '上传时间',
  uploadFile: '上传文件',
  uploadApp: '上传应用',
  deleteFile: '删除文件',
  all: '全部',
  app: '应用',
  file: '文件',
  doc: '文档',
  img: '图片',
  other: '其他',
  batchUploadFile: '批量上传/删除文件',
  batchUpload: '批量上传文件',
  batchDel: '批量删除文件',
  nameTip: '为该设备命名，方便以后的日常管理操作！',
  deleteTip: '云手机删除后不可恢复，确认要彻底删除云手机吗？',
  deleteSharePhone: '确认删除分享云手机吗？',
  groupControlSync: '群控同步',
  groupControlSync1: '群控',
  groupSelectTips: '注意：如果选择了其他账号下的分组，则该云手机将转移至其他账号的分组里（云手机归属权将属于其他账号）',
  setSuccess: '执行成功',
  openStatus: '连接状态',
  open: '打开',
  close: '关闭',
  yes: '是',
  no: '否',
  remarkPlaceholder: '请填写云手机备注',
  allPhoneClosed: '已关闭所有云手机',
  closePhone: '云手机已关闭',
  checkedHasClosed: '所选云手机已关闭',
  ruleNameMsg: '名称最多50个字符',
  editShareGroupMsg: '分享的云手机不可修改分组',
  editNameSuccess: '设备名称修改成功',
  renewal: '急需续费',
  sortPlaceholder: '请输入一个大于等于0的数字',
  editSeq: '修改序号',
  ipCheckTips1:
    '1.不同的IP查询渠道，可能会存在一定的检测结果差异！若代理检测结果与实际信息不符，可以调整IP查询渠道以确保检测结果的准确性！',
  ipCheckTips2:
    '2.若该手机环境的指纹设置中，勾选了基于IP生成：语言、时区、地理位置，请确保选择的IP查询渠道的检测结果与实际信息相符，否则会导致上述几处自动生成后产生误差！',
  hostTips: '代理类型://主机:端口:账号:密码，填写该格式，光标离开后自动拆分。',
  name: '设备名称',
  phoneEpire: '云手机已过期',
  setGroupMain: '设置主控',
  mainGroup: '主控',
  controlled: '被控',
  qunkongTip: '必须要有两台打开的云手机才可以设置群控',
  controlTip: '最大支持群控50台云手机，群控过程中请保持全部云手机窗口大小和画面一致。',
  uninstall: '卸载',
  uploadPhone: '上传至手机',
  alreadyUpload: '已经上传过了',
  maxFile: '上传文件不能大于1GB',
  deleteFileTip: '您确定删除此文件吗？文件删除后，不可恢复，请谨慎操作！',
  isOpening: '正在打开该手机',
  batchOpenMax: '最多一次批量打开20台',
  uploadTip: '上传文件后，勾选需要上传的文件然后点击“上传至手机”，上传后的文件存放在手机Downloads目录下',
  uploadSizeTip: '每个账号拥有{size}G的云端存储空间，此处上传文件是上传至云端，并非直接上传至手机环境中。',
  uploadApkTip: '上传应用后，勾选需要上传的应用然后点击“安装”',
  controlType: '待设置群控设备',
  controlType1: '已设置群控设备',
  actionMore: '加载更多',
  adControl: '添加群控',
  delControl: '取消群控',
  createControlTip: '创建群控要选中2个及以上的手机',
  releaseControl: '全部取消群控',
  controlDeviceTip: '{arg}的主控设备',
  slaveControlDeviceTip: '{arg}的被控设备',
  noEditPermission: '您当前无编辑云手机权限，请联系账号管理员开通',
  openSelect: '打开勾选',
  selectAddControl: '将勾选加入群控',
  selectRemoveControl: '将勾选取消群控',
  cancelControlClosePhone: '取消群控时关闭云手机窗口',
  addContrlOpenPhone: '加入群控时打开云手机窗口',
  reInstall: '重装系统',
  newPhone: '一键新机',
  deviceOverdueTip: '当前账号未进行个人/企业认证，所有的云手机只能使用3天，当前已到期！请尽快到“个人中心”进行个人/企业认证后继续使用！',
  confirmBuy: '确认购买',
  buyDeviceTip: '您未进行个人/企业认证，购买的云手机只能使用3天，购买后请尽快到“个人中心”进行个人/企业认证。您是否继续购买？',
  emptyFileTip: '空文件禁止上传',
  versionTip: '当前客户端版本不支持打开手机环境，请升级至最新客户端后再打开使用，点击顶部“发现新版本”进行更新。',
  updateCoreTip: '请更新{arg}内核使用',
  unit: '台',
  江苏: '江苏',
  香港: '香港',
  大陆: '大陆',
  新加坡: '新加坡',
  region: '区域',
  enterRegion: '请选择区域',
  buyMaxTip: '单次购买最多50台',
  recoverTip: '执行“恢复出厂设置”操作后，您之前所上传的应用、文档、图片等文件都会被清除掉且不可恢复。是否继续执行？',
  reinstallTip: '执行“重装系统”操作后，需要重新打开手机，您之前所上传的应用、文档、图片等文件都会被清除掉且不可恢复。是否继续执行？',
  randomPhoneTip:
    '执行“一键新机”操作，会随机生成新的指纹信息，例如：品牌名、设备型号、IMEI、Wi-Fi等指纹信息，请谨慎操作！同时建议您不要连续去点击“一键新机”按钮！是否继续执行？',
  randomPhoneTip1: '执行“一键新机”操作，会随机生成新的指纹信息，建议您不要连续去点击“一键新机”按钮，超过次数后无法执行！是否继续？',
  quantity: '设备数量',
  openPhoneDisableTip: '所选环境中包含已打开的环境，请重新选择',
  uploadNameMAx: '文件名称不能超过100个字符',
  apiDoc: 'API 文档',
  scriptMarket: '脚本市场'
}
