export default {
  tips: 'Adjust Browser Window Bounds',
  phoneTips: 'Adjust Profile Window Bounds',
  reset: 'Adjust Browser Window Bounds',
  resetPhone: 'Adjust Phone Window Bounds',
  resetEnv: 'Adjust Profile Window Bounds',
  startBtn: 'Start',
  box: 'Box',
  diagonal: 'Diagonal',
  startX: 'Start Position: ',
  windowSize: 'Size: ',
  width: 'Width:',
  height: 'Height:',
  col: 'Col:',
  space: 'Space: ',
  spaceX: 'Space X:',
  spaceY: 'Space Y:',
  offset: 'Offset: ',
  minSizeTips: 'Note: The min width of the browser profile is {width}px, and the min height is {height}px.',
  minSizeTipsPhone: 'Note: The min width of the phone window is {width}px, and the min height is {height}px.',
  minSizeTipsEnv: 'Note: The min width of the profile window is {width}px, and the min height is {height}px.',
  startXRuleMsg: 'Start X is required.',
  startYRuleMsg: 'Start Y is required.',
  widthRuleMsg: 'Width is required.',
  heightRuleMsg: 'Height is required.',
  colRuleMsg: 'Col is required.',
  spaceXRuleMsg: 'Space X is required.',
  spaceYRuleMsg: 'Space Y is required.',
  offsetXRuleMsg: 'Offset X is required.',
  offsetYRuleMsg: 'Offset Y is required.',
  resetFlexable: 'Adaptive Arrangement',
  monitor: 'Monitor',
  orderBySeq: 'Order By Seq',
  desc: 'Descending',
  asc: 'Ascending'
}
