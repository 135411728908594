export default {
  tips: '重新排列窗口',
  phoneTips: '重新排列环境窗口',
  reset: '重新排列窗口及调整大小',
  resetPhone: '重新排列手机窗口及调整大小',
  resetEnv: '重新排列环境窗口及调整大小',
  startBtn: '开始排列',
  box: '宫格排列',
  diagonal: '对角线排列',
  startX: '起始位置：',
  windowSize: '窗口大小：',
  width: '宽:',
  height: '高:',
  col: '每行窗口数:',
  space: '窗口间距：',
  spaceX: '横向间距:',
  spaceY: '纵向间距:',
  offset: '窗口偏移量：',
  minSizeTips: '注意：浏览器窗口可缩放的最小宽度为{width}px，最小高度为{height}px。',
  minSizeTipsPhone: '注意：手机窗口可缩放的最小宽度为{width}px，最小高度为{height}px。',
  minSizeTipsEnv: '注意：环境窗口可缩放的最小宽度为{width}px，最小高度为{height}px。',
  startXRuleMsg: '请填写起始位置X',
  startYRuleMsg: '请填写起始位置Y',
  widthRuleMsg: '请填写窗口宽度',
  heightRuleMsg: '请填写窗口高度',
  colRuleMsg: '请填写每行窗口数',
  spaceXRuleMsg: '请填写横向间距',
  spaceYRuleMsg: '请填写纵向间距',
  offsetXRuleMsg: '请填写横向偏移量',
  offsetYRuleMsg: '请填写纵向偏移量',
  resetFlexable: '一键自适应排列',
  monitor: '显示器',
  orderBySeq: '按序号排列',
  desc: '倒序',
  asc: '正序'
}
