export default {
  zh: {
    nameRequired: '请输入姓名',
    nameLenLimit: '姓名不超过20位',
    nameSchema: '姓名不能包含特殊字符',
    usernameRequired: '请输入用户名',
    subUsernameLenLimit: '用户名不超过30位',
    usernameLenLimit: '用户名不超过20位',
    usernameLenLimit1: '用户名/手机号/邮箱不超过60位',
    usernameSchema: '用户名为英文、数字或下划线',
    usernameSchema1: '请输入用户名（英文、数字、下划线）或手机号或邮箱',
    passRequired: '请输入密码',
    passMinLen: '密码长度不小于6位',
    rstPassMinLen: '密码长度不小于8位',
    passMaxLen: '密码长度不能超过30位',
    telRequired: '请输入手机号码',
    telBeNumber: '手机号码为纯数字',
    telMax: '手机号码不能超过20位',
    telInvalid: '请输入正确的手机号',
    emailInvalid: '请输入正确的邮箱格式',
    emialMaxLen: '邮箱账号不能超过50位',
    qqInvalid: '微信/QQ只包含英文字母和数字',
    qqMaxLen: '微信/QQ不能超过30位',
    speicalCharacters: '长度为8~30个字符，需包含：大小写字母、数字、特殊字符',
    spaceError: '密码不能包含空格',
    usernamephoneRequired: '请输入用户名/手机号/邮箱'
  },
  ru: {
    nameRequired: 'Введите имя',
    nameLenLimit: 'Имя не должно превышать 20 символов',
    nameSchema: 'Имя не может содержать специальные символы.',
    usernameRequired: 'Введите имя пользователя',
    subUsernameLenLimit: 'Имя пользователя не должно превышать 30 символов.',
    usernameLenLimit: 'Имя пользователя не должно превышать 20 символов.',
    usernameLenLimit1: 'Не более 60 символов для имени/телефона/почты.',
    usernameSchema: 'Имя пользователя должно содержать английские буквы и цифры, или нижнее подчеркивание',
    usernameSchema1: 'Введите имя пользователя (буквы, цифры, подчеркивания), телефон или почту.',
    passRequired: 'Введите пароль',
    passMinLen: 'Длина пароля должна быть не менее 6 символов',
    rstPassMinLen: 'Длина пароля должна быть не менее 8 символов',
    passMaxLen: 'Длина пароля не должна превышать 30 символов',
    telRequired: 'Введите номер телефона',
    telBeNumber: 'Номер телефона должен состоять только из цифр',
    telMax: 'Номер телефона не должен превышать 20 символов',
    telInvalid: 'Введите правильный номер телефона',
    emailInvalid: 'Введите ваш e-mail',
    emialMaxLen: 'Адрес электронной почты не должен превышать 50 символов',
    qqInvalid: 'WeChat/QQ должны содержать только английские буквы и цифры',
    qqMaxLen: 'WeChat/QQ не должны превышать 30 символов',
    speicalCharacters: '8-30 символов, буквы, цифры, спецсимволы.',
    spaceError: 'Пароль не может содержать пробелы',
    usernamephoneRequired: 'Введите имя/телефон/эл. почту'
  },
  en: {
    nameRequired: 'Name is required.',
    nameLenLimit: 'Name cannot exceed 20 characters.',
    nameSchema: 'Name cannot contain special characters',
    usernameRequired: 'Username is required.',
    subUsernameLenLimit: 'Username cannot exceed 30 characters.',
    usernameLenLimit: 'Username cannot exceed 20 characters.',
    usernameLenLimit1: 'Username/Phone number/Email should not exceed 60 characters.',
    usernameSchema: 'Username is English, number or underscore.',
    usernameSchema1: 'Enter username (letters, numbers, underscores) or phone number or email.',
    passRequired: 'Password is required.',
    passMinLen: 'The min password length is 6 digits.',
    rstPassMinLen: 'The min password length is 8 digits.',
    passMaxLen: 'Password cannot exceed 30 characters.',
    telRequired: 'Phone Number is required.',
    telBeNumber: 'The phone number must be numbers.',
    telMax: 'Cannot exceed 20 characters.',
    telInvalid: 'Please enter the valid phone',
    emailInvalid: 'Please enter the valid email address.',
    emialMaxLen: 'Email address cannot exceed 50 characters.',
    qqInvalid: 'Wechat/QQ number only contains English letters and numbers.',
    qqMaxLen: 'Wechat/QQ cannot exceed 30 characters.',
    speicalCharacters: '8-30 characters, include letters, digits, special characters.',
    spaceError: 'Password cannot contain spaces',
    usernamephoneRequired: 'Please enter username/phone/email'
  }
}
