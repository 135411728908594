export default {
  baseinfo: 'Basic',
  name: 'Name',
  username: 'Username',
  email: 'Email',
  bindEmail: 'Bind Email',
  qqPlaceholder: 'Please enter your Wechat/QQ',
  qq: 'Wechat/QQ',
  save: 'Save',
  changeTel: 'Change Phone Number',
  changeEmail: 'Change Email',
  currentTel: 'Phone Number',
  currentEmail: 'Email Number',
  newEmail: 'New Email Number',
  codePlaceholder: 'Filter by nation code',
  newTel: 'New Number',
  telPlaceholder: 'Enter your phone number',
  verifyCodePlaceholder: 'Enter 6-digit code',
  verifyEmailPlaceholder: 'Enter 6-digit code',
  emailverifyCode: 'Please enter the verification code',
  verifyCode: 'Verification Code',
  passwordPlaceholder: 'Please enter your login password',
  password: 'Password',
  confirmChange: 'Confirm And Save',
  passwordSet: 'Reset Password',
  oldPassPlaceholder: 'Please enter your current password',
  oldPassword: 'Current Password',
  newPassPlaceholder: 'Please enter your new password',
  newPass: 'New Password',
  newPassAgain: 'Please confirm your new password',
  confirmPassword: 'Confirm Password',
  confirmPassword1: 'Enter your password again.',
  savepass: 'Save',
  passRule1: 'Please confirm your new password',
  passRule2: 'The confirm passwords entered twice do not match.',
  nationRule: 'Please select a country',
  resentTips: 'Resend in {seconds} seconds',
  send: 'Get Code',
  qqChangeConfirm: 'Confirm to update email?',
  passChangeConfirm: 'Confirm to reset password?<br/>Please log in with the new password after reset!',
  passChangeSuccess: 'Success',
  telChangeConfirm: 'Confirm to bind this mobile number?',
  telChangeSuccess: 'Success',
  telChangeError: 'The mobile phone number has been bound by another account, please change it!',
  emailChangeError: 'The current email is already linked to another account. Please change your email',
  sendSuccess: 'Success',
  forgetUsername: 'Forget Username',
  findAccount: 'Find your Username',
  yourUsername: 'Your Username',
  getBack: 'Find',
  welcome: 'Log in to',
  backLogin: 'Back To Log in',
  verifyRule1: 'Please enter the verification code.',
  verifyRule2: 'The verification code is 6 digits.',
  usernameCopied: '{name} has copied.',
  usernamePlaceholder: 'Please enter your username.',
  usernamePlaceholder1: 'Please enter username/phone/email',
  loginPassPlaceholder: 'Please enter your password.',
  loginPassPlaceholder1: 'Please enter your password.',
  loginPass: 'Password',
  rememberPass: 'Remember Password',
  forgetPass: 'Forget Password',
  login: 'Login',
  toRegister: "Don't have an account yet? To Sign up",
  signingIn: 'Logging in...',
  welcomeRegister: 'Sign up for',
  registerUsername: 'Please enter your username with a~z,0~9',
  tel: 'Phone Number',
  registerQQPlaceholder: 'Optional, please enter your Wechat/QQ',
  registerEmail: 'Optional, please enter your email',
  inviteCodePlaceholder: 'Optional, please enter the invitation code',
  inviteCode: 'Invitation Code',
  register: 'Sign Up',
  emailRegister: 'With Email',
  phoneRegister: 'With Phone Number',
  readAndKnow: 'I have read and agree',
  userPolicy: 'User Service Agreement',
  haveAccount: 'Already have an account?',
  toLogin: 'Login Now',
  userPolicyDialog: 'User Service Agreement',
  registerUsernameRule: 'Username must be at least 6 characters.',
  inviteCodeRule: 'The invitation code cannot exceed 10 characters.',
  policyValid: 'Please read and agree to the "User Service Agreement"',
  registerSuccess: 'Registration succeeded. Do you want to download the software client now?',
  downloadNow: 'Download',
  downloadLater: 'Download Later',
  registerAndLogin: 'Registration succeeded, please log in.',
  resetPass: 'Reset Password',
  resetConfirm: 'Confirm',
  resetSuccess: 'Success',
  otherSetting: 'Password Verification',
  opendeleteWindowvVer: 'Password verification for "Permanently Delete"',
  '2fAuth': 'Two-Factor Authentication',
  enable2fa: 'Enable 2FA',
  enableConfirm:
    'Confirm to enable Two-Factor Authentication?<p class="q-mt-sm">Once enabled, you will need to pass 2FA verification when you log in.</p><p class="q-mt-sm text-red">Note: Please ensure that your can receive SMS normally, or you may not pass Two-Factor Authentication.</p>',
  disable2faConfirm:
    'Confirm to disable Two-Factor Authentication?<p class="q-mt-sm">Once disabled, you can only use username and password to  log in.</p>',
  enablePasswordVerification:
    'Cofirm to enable completely delete browser profiles password verification? <p class="q-mt-sm text-red">The password verification will be required while you delete the browser profiles.</p>',
  disablePasswordVerification:
    'Confirm to disable completely delete browser profiles password verification? <p class="q-mt-sm text-red">The password verification will be disabled while you delete the browser profiles.</p>',
  authenticator: 'Authenticator',
  bind: 'Bind',
  unbind: 'Unbind',
  bound: 'Bound',
  secretKey: 'Secret Key',
  googleAuthCode: 'Authenticator Code',
  googleCodePlaceholder: 'Please enter the code generated by authenticator',
  bindTitle: 'Binding Authenticator',
  scanQrCode: 'Scan QR Code',
  authValidation: 'Validation',
  scanTips: 'Note: Scan the QR code with authenticator or copy the Secret Key to use.',
  unbindTitle: 'Unbind Authenticator',
  recommended: 'Recommended Authenticator',
  googleAuthenticator: 'Google Authenticator',
  microsoftAuthenticator: 'Microsoft Authenticator',
  tencentAuthenticator: 'Tencent Authenticator',
  sms: 'SMS',
  validationMethod: 'Validation Method',
  smsCode: 'SMS Code',
  emailCode: 'Email Code',
  requiredEmail: 'Enter your email address.',
  emailPlaceholder: 'Enter your email address',
  emailVerification: 'Email Verification',
  phoneVerification: 'Phone Verification',
  checkType: 'Verification Method',
  '2fAuthTips': 'Please subscribe to the package to enable this feature.',
  vistualTelTips:
    'Accounts registered with virtual phone numbers are at risk of being stolen. When logging into the system, security verification will be triggered. Please make sure that this number can receive SMS verification codes at any time. It is strongly recommended to register an account with a physical phone number.',
  secureAuth: 'Identity Security Verification',
  selectSymbol: 'Please select settlement currency',
  SettlementCurrency: 'Settlement currency',
  SettlementCurrencyMsg: 'The currency used for settlement cannot be changed after registration. Please choose carefully!',
  securitySetting: 'Security Settings',
  unbound: 'unbound',
  emailAuth: 'Mailbox Authentication',
  bindPhone: 'Bind Mobile Number',
  telAuthenticationTitle: 'Verify Mobile Number',
  accreditation: 'Verification',
  passwordLogin: 'Password Login',
  verificationLogin: 'Verification Login',
  userNamephone: 'Username/Phone/ Email',
  phoneLoginTip: 'Exclude area code when entering phone number.',
  authenticated: 'Authenticated',
  phoneEmailLoginTip: 'Note: You can log in to the {arg} directly using the successfully authenticated Phone number/Email.',
  toAuthen: 'Authenticate',
  notCertified: 'Not Certified',
  isFreeAccountBindTip: 'Note: After subscribing to the package, binding is supported.',
  verificationType: 'Verification Type',
  personalVer: 'Personal Verification',
  enterpriseVer: 'Enterprise Verification',
  AuthenticationName: 'Authentication Name',
  detailAuth: 'Authentication Details',
  upgradeEnterprise: 'Upgrade Enterprise Authentication',
  openTransBrowserPassword: 'Password verification for "Share/Transfer Profiles"',
  enablePasswordVerification1:
    'Confirm enabling password verification for transfer/share profiles?<p class="q-mt-sm text-red">Once enabled, password verification will be required when transferring/sharing in the browser profile.</p>',
  disablePasswordVerification1:
    'Confirm disabling password verification for transfer/share profiles?<p class="q-mt-sm text-red">Once disabled, password verification will not be required when transferring/sharing in the browser profiles.</p>',
  changePassword: 'By Current Password',
  changeVerificationCode: 'By Verification Code',
  emailResitertip: 'Do not use temporary email addresses to register accounts, as these accounts may be at risk of being inaccessible.',
  privacyPolicy: 'Privacy Policy'
}
